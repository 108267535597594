.loading-bars-animation {
  animation: throb 1s infinite;
}

@keyframes throb {
  0%,
  40%,
  100% {
    transform: scaleX(1) scaleY(1);
    opacity: 0.2;
  }
  20% {
    transform: scaleX(1.5) scaleY(2);
    opacity: 1;
  }
}
