/*
  CSS below makes an unordered list into a comma separated list on a single line
  and places a space between each item.
  https: //stackoverflow.com/a/38353985/3120546
*/

ul.comma-list {
  @apply flex flex-row list-none gap-x-1;
}

ul.comma-list li {
  @apply inline-block;
}

ul.comma-list li:not(:last-child):after {
  content: ",";
}
