.liveButtonBg {
  background: conic-gradient(
    from 90deg at 50% 50%,
    #ff8fa5,
    #ff9d79,
    #ffb15d,
    #37d1ff,
    #967aff,
    #b47fff,
    #c880ff,
    #e36bb5,
    #ff7388
  );
  width: 80px;
  height: 80px;
  position: absolute;
  animation: spinGradient 2.5s linear infinite;
}

@keyframes spinGradient {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
