.stickynote {
  @apply relative pl-4 pt-3 pr-10 mb-1 overflow-hidden bg-yellow-100 text-yellow-900;
}

.stickynote > textarea {
  @apply bg-transparent block w-full resize-none border-0
  border-b border-transparent p-0 pb-2 mb-1 focus:border-yellow-100 focus:ring-0;
}

.stickynote > textarea::placeholder {
  @apply text-yellow-600;
}

.stickynote:before {
  content: "";
  @apply absolute block w-0 top-0 right-0 bg-yellow-300 drop-shadow
  border-t-white border-r-white border-b-yellow-300 border-l-yellow-300 border-solid
    border-t-0 border-r-[16px] border-b-[16px] border-l-0;
}
