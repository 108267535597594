.jump {
  animation: jump 4.4s ease-in-out infinite;
}

@keyframes jump {
  0%,
  46% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  53% {
    transform: translateY(0px);
  }
  57% {
    transform: translateY(-10px);
  }
  61% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0);
  }
}
