.loader {
  animation: loader 2.6s ease-in-out infinite;
}

.loaderDelayed {
  animation: loaderDelayed 2.6s ease-in-out infinite;
  animation-delay: -375ms;
}

@keyframes loader {
  0%,
  100% {
    width: 48%;
    opacity: 1;
  }
  50% {
    width: 95%;
    opacity: 0.5;
  }
}

@keyframes loaderDelayed {
  0%,
  100% {
    width: 30%;
    opacity: 1;
  }
  50% {
    width: 66%;
    opacity: 0.7;
  }
}
