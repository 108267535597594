.ant-picker-panel-container {
  box-shadow: none !important;
  border: 1px solid #e5e7eb !important;
}

.ant-picker-header-view {
  line-height: 25px !important;
}

.ant-input-number-input-wrap,
.ant-input-number-input {
  height: 100% !important;
}

.ant-picker-cell {
  padding: 4px 0 4px 0 !important;
}

.ant-input-number-input-wrap {
  height: 100% !important;
  max-height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: 0 !important;
}

.ant-input-number-input {
  top: 0;
  left: 0;
  position: absolute;
  border: 0 !important;
  outline: 0 !important;
  height: 100% !important;
  box-shadow: 0 !important;
  width: calc(100% - 20px) !important;
  --tw-ring-shadow: "none" !important;
  border-color: "transparent" !important;
  --tw-ring-offset-shadow: "none" !important;
}

#navigation-cal .ant-picker-header-super-prev-btn,
#navigation-cal .ant-picker-header-super-next-btn,
#navigation-cal .ant-picker-header-prev-btn,
#navigation-cal .ant-picker-header-next-btn {
  display: none !important;
}
